.placeholder-gif {
    margin: 50px;
    width: 480px;
    height: 360px;
}

.search-input {
    height: 2em;
    width: 20em;
    margin: 2em;
}

h2 {
    height: 1em;
}

.canvas {
    position: absolute;
    top: -500px;
}

.search-text-container {
    display: flex;
    justify-content: center;
    margin: 1em 0 2em;
}

#search-text-label {
    margin: 0;
}
#search-text {
    margin: 0 0 0 1rem;
}